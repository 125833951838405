/* Footer */
.footer {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
}

.footer-text > span {
  color: var(--primary-color);
}
