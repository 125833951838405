/* Header */
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: var(--bg);
  box-shadow: 0px 5px 26px 17px var(--bg);
  z-index: 99;
}

.nav-items {
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}

.logo > a {
  color: var(--primary-color);
  font-size: 1.2rem;
  text-decoration: none;
}

.nav-links {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu {
  visibility: visible;
  opacity: 1;
  transition: visibility 0.5s, opacity 0.5s linear;
}

.hide {
  visibility: hidden;
  opacity: 0;
}

.menu button {
  cursor: pointer;
  background: none;
  color: var(--header-link);
  border: none;
  outline: none;
  font-size: 1.2rem;
}

.links {
  background-color: var(--bg);
  opacity: 0.85;
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 90%;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  transform: translateX(100%);
  transition: transform 0.5s ease-in-out;
}

.nav-social-links {
  display: flex;
  justify-content: space-evenly;
}

.nav-button {
  text-decoration: none;
}

.nav-btn-link {
  font-size: 3rem;
  color: var(--primary-color);
}

.nav-btn-link:hover,
.nav-btn-link:focus {
  color: var(--link-hover);
}

.show-nav {
  transform: translateX(0);
}

.close {
  position: absolute;
  top: 1rem;
  right: 0.75rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

.close-icon {
  font-size: 2rem;
  color: var(--primary-color);
}

.link-wrapper {
  margin-bottom: 2rem;
  width: 50%;
  padding: 1rem 0;
  text-align: center;
  list-style: none;
}

.link {
  text-decoration: none;
  font-size: 1.5rem;
  color: var(--header-link);
  transition: color 0.25s ease-in-out;
}

.active {
  color: var(--header-link-active);
}

.link:hover {
  color: var(--primary-color);
}

/* Big Screens */
@media screen and (min-width: 700px) {
  /* Navbar */
  .menu {
    display: none;
  }

  .close {
    display: none;
  }

  .nav-links {
    width: 60%;
  }

  .links {
    position: static;
    transform: none;
    transition: none;
    flex-direction: row;
    opacity: 1;
    height: auto;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .link-wrapper {
    width: auto;
    margin-bottom: 0;
  }

  .link {
    font-size: 1rem;
  }

  .nav-social-links {
    width: 4rem;
    justify-content: space-between;
  }

  .nav-btn-link {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 810px) {
  /* Navbar */
  .nav-links {
    width: 50%;
  }
}

@media screen and (min-width: 1000px) {
  /* Navbar */
  .nav-links {
    width: 40%;
  }
}

@media screen and (min-width: 1300px) {
  /* Navbar */
  .nav-items {
    padding: 0;
  }

  .nav-links {
    width: 35%;
  }
}
