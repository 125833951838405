.about-page {
  margin: 0 1.2rem;
}

.about-page-title {
  margin-bottom: 2rem;
}

.social-links {
  margin-bottom: 1rem;
}

.about-button {
  margin-right: 1rem;
  cursor: pointer;
}

.about-btn-link {
  color: var(--primary-color);
  height: 1.75rem;
  width: 1.75rem;
}

.about-btn-link:hover,
.about-btn-link:focus {
  color: var(--link-hover);
}

.about-resume-link {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.about-p {
  display: inline-block;
  margin-right: 1rem;
  color: grey;
}

.about-btn {
  width: 7rem;
  text-decoration: none;
  padding: 0.5rem 1rem;
  background-color: var(--primary-color);
  border-radius: 3rem;
  border: none;
  outline: none;
  cursor: pointer;
  color: var(--bg);
  display: flex;
  align-items: center;
  transition: background-color 0.25s ease-in;
}

.about-btn:hover,
.about-btn:focus {
  background-color: var(--link-hover);
}

.download {
  height: 1.5rem;
  width: 1.5rem;
  margin-left: 0.5rem;
}

.about-summary {
  color: var(--primary-color);
  margin-bottom: 2rem;
}

.about-section {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.about-ui {
  list-style: none;
  margin-bottom: 2rem;
}

.about-li {
  margin-bottom: 1rem;
  color: grey;
}

.group-li {
  margin-bottom: 0;
}

.comment {
  color: var(--header-link-active);
}

.lang > span {
  color: var(--primary-color);
}

@media screen and (min-width: 570px) {
  .about-page {
    margin: 0 3rem;
  }

  .about-links {
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .social-links {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }

  .about-resume-link {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }

  .about-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }

  .group-ui {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }
}

@media screen and (min-width: 1280px) {
  .about-page {
    margin: auto;
  }
}
