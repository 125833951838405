/* Main */
.main {
  height: 100vh;
  background: url('../images/bg.jpg') center / cover no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.greet {
  font-size: 3.5rem;
  color: var(--body-color);
  margin: 1rem;
  font-weight: 600;
  text-align: center;
}

.greet > span {
  color: var(--primary-color);
  font-weight: 700;
}

.main-social-links {
  width: 8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-btn-link {
  text-decoration: none;
  font-size: 3rem;
  color: var(--header-link);
}

.main-btn-link:hover {
  color: var(--primary-color);
}

/* About */
.about {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.description {
  max-width: 40rem;
  text-align: center;
  padding: 0 1rem;
}

.resume {
  margin-top: 0.75rem;
  font-size: 1.2rem;
  text-align: center;
}

.resume-link {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: bold;
}

.resume-link:hover {
  color: var(--link-hover);
}

/* Selected Projects */
.selected-projects {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cards {
  margin: 0 1.25rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.5rem;
}

.all-projects {
  text-align: center;
  font-size: 1.2rem;
  margin-top: 1rem;
}

.all-projects > a {
  text-decoration: none;
  color: var(--primary-color);
  font-weight: bold;
}

.all-projects > a:hover {
  color: var(--link-hover);
}

/* Contact */
.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1.25rem;
  margin-top: 1rem;
}

.contact-form {
  max-width: 30rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--primary-color);
  background-image: linear-gradient(to right, var(--primary-color), #45b6d9);
  border-radius: 1rem;
  padding: 1rem;
}

.input {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.75rem;
}

.form-label {
  margin-bottom: 0.25rem;
}

.user-input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: var(--header-link);
  border: none;
  outline: none;
  border-radius: 1rem;
}

.form-input {
  height: calc(1.5em + 0.75rem + 2px);
}

.form-input-textarea {
  resize: none;
}

.user-input:focus {
  box-shadow: 0px 0px 10px 3px #9c9200;
}

.submit-form {
  display: flex;
  justify-content: center;
}

.submit {
  padding: 1rem 2rem;
  border-radius: 2rem;
  border: none;
  outline: none;
  font-size: 1rem;
  background-color: var(--bg);
  color: var(--header-link);
  font-weight: bold;
  transition: all 0.5s;
  cursor: pointer;
}

.submit {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.submit::after {
  content: '»';
  color: #45b6d9;
  /* color: var(--header-link); */
  font-size: 1.25rem;
  position: absolute;
  opacity: 0;
  top: 12px;
  right: -20px;
  transition: 0.5s;
}

.submit:hover,
.submit:focus {
  padding-right: 48px;
  padding-left: 16px;
}

.submit:hover::after,
.submit:focus::after {
  opacity: 1;
  right: 15px;
}

/* Big Screens */
@media screen and (min-width: 650px) {
  /* Main */
  .greet {
    font-size: 4rem;
  }

  /* Selected Projects */
  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  /* Contact */
  .contact-form-inputs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .input-email {
    grid-column: 1/3;
  }

  .input-message {
    grid-column: 1/3;
  }
}

@media screen and (min-width: 700px) {
  /* Main */
  .greet {
    font-size: 5rem;
  }
}

@media screen and (min-width: 810px) {
  /* Contact */
  .contact {
    margin-top: 3rem;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .contact > .title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 0.5;
  }

  .contact-form {
    flex-grow: 1;
    max-width: 35rem;
  }
}

@media screen and (min-width: 1300px) {
  /* Selected Projects */
  .selected-projects {
    align-items: normal;
  }

  .cards {
    margin: 0;
  }

  /* Contact */
  .contact {
    margin: 3rem auto;
  }
}
