.projects-page {
  min-height: 96vh;
  margin: 0 1.2rem;
}

.project-p {
  text-align: center;
}

@media screen and (min-width: 1280px) {
  .projects-page {
    margin: auto;
  }
}
