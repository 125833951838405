@import url('./pages/Utilities.css');
@import url('./components/Header.css');
@import url('./pages/Home.css');
@import url('./pages/AboutPage.css');
@import url('./pages/Projects.css');
@import url('./pages/ContactPage.css');
@import url('./components/Footer.css');

:root {
  --primary-color: #682ae9;
  --body-color: #dbdbdb;
  --header-link: #dbdbdb;
  --header-link-active: #555;
  --bg: #0e1212;
  --input-border: #007bff;
  --link-hover: #8450f3;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

html,
body {
  overflow-x: hidden !important;
  background-color: var(--bg);
  color: var(--body-color);
}
