/* Utilities */
.container {
  max-width: 80rem;
  margin: 0 auto;
}

.spacer {
  padding-top: 5rem;
}

.title {
  margin-top: 2rem;
  text-align: center;
  margin-bottom: 1.25rem;
  color: var(--primary-color);
}

.card {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  background-color: var(--primary-color);
  background-image: linear-gradient(to right, var(--primary-color), #45b6d9);
  border-radius: 0.35rem;
  /* box-shadow: 0 0.5rem 1rem #8459dd; */
}

.card-img {
  border-top-left-radius: calc(0.35rem - 1px);
  border-top-right-radius: calc(0.35rem - 1px);
  max-width: 100%;
  max-height: 100%;
}

.card-title {
  padding: 1rem;
  font-weight: 600;
}

.card-description {
  padding: 0 1rem 1rem;
}

.card-link {
  text-decoration: none;
  color: var(--header-link);
}

/* Big Screens */
@media screen and (min-width: 650px) {
  /* Utiities */
  .card {
    width: 400px;
  }
}

@media screen and (min-width: 720px) {
  /* Utiities */
  .card {
    width: 320px;
  }
}

@media screen and (min-width: 780px) {
  /* Utiities */
  .card {
    width: 350px;
  }
}

@media screen and (min-width: 865px) {
  /* Utiities */
  .card {
    width: 400px;
  }
}
